/* eslint-disable no-useless-escape */
import Vue from "vue";
import VueRouter from "vue-router";
import * as auth from "../utils/auth";
import store from "../store";
import _ from "lodash";
import Qs from "qs";
import defaultMenu from "./menu";
import localStorage from "../plugins/localstorage";

Vue.use(VueRouter);
const allowOnDisabledPages = ["info@coolshop.dk", "kq+firma@tric.dk", "lasse@lambertsen.biz", "kq@tric.dk", "smartcheckout@coolrunner.dk", "info@cykelpartner.dk"];

const requireComponent = require.context("@/views", true, /\.vue$/);

let views = [];

requireComponent.keys().forEach((fn) => {
  let fileName = fn;
  if (fileName.match(/components/)) return;

  const TBA = !!fileName.match(/_TBA/);

  const rootFolder = _.first(fileName.match(/\.\/[\s\S]+\//));

  const path = fileName
    .replace(/\/Index/, "") //If Index file remove from path
    .replace(/^\.\//, "/")
    .replace(/.vue/, "") //Remove fileextension
    .replace(/\.\w+$/, "")
    .replace(/^\.$/, "")
    .replace(/^\.\//, "")
    .replace(/_TBA/, "")
    .toLowerCase();

  let mainComponent = fileName.replace(/^\.\//, "");

  let components = {
    main: () => import("@/views/" + mainComponent),
  };

  // eslint-disable-next-line no-irregular-whitespace
  if (rootFolder && mainComponent.match(/\_/) && !TBA) {
    let parent = rootFolder.toLowerCase();
    parent = parent.replace(/\.\//, "").replace(/\/$/, "").replace(/\//, "_");

    components[parent] = () => import("@/views/" + mainComponent);
    const pindex = _.findIndex(views, ["name", parent]);

    if (!views[pindex].children) views[pindex].children = [];

    views[pindex].children.push({
      path: path.replace(/_/g, ""),
      name: path.replace(/^\//, "").replace(/\//g, "_").replace(/\_\_/g, "_") || "home",
      components,
      meta: {
        disabled: TBA,
      },
    });

    return;
  }
  views.push({
    path: path.replace(/_/g, ""),
    name: path.replace(/^\//, "").replace(/\//g, "_") || "home",
    components,
    meta: {
      disabled: TBA,
    },
  });
});

views.push({
  path: "/returns/new",
  name: "returns_portal_new",
  components: { main: () => import("@/views/Returns/_Page.vue") },
});
views.push({
  path: "/returns/:id",
  name: "returns_portal_id",
  components: { main: () => import("@/views/Returns/_Page.vue") },
});

const scIndex = _.findIndex(views, (v) => v.name === "tools_smartcheckout_checkout");

if (scIndex !== -1) {
  views.splice(scIndex, 1, { ...views[scIndex], path: "/integrations/smartcheckout/:smartcheckout_id" });
}

const scsmEditIndex = _.findIndex(views, (v) => v.name === "tools_smartcheckout_shippingmethod");

if (scsmEditIndex !== -1) {
  views.splice(scsmEditIndex, 1, { ...views[scsmEditIndex], path: "/integrations/smartcheckout/:smartcheckout_id/shippingmethod/:shipping_method_id" });
}

const lgIndex = _.findIndex(views, (v) => v.name === "tools_lights_group");

if (lgIndex !== -1) {
  views.splice(lgIndex, 1, { ...views[lgIndex], path: "/tools/lights/group/:group_id" });
}

const labelsIndex = _.findIndex(views, (v) => v.name === "labels");
if (labelsIndex !== -1) {
  views.push({ ...views[labelsIndex], name: "labels_view", path: "/labels/:view_id" });
}

const detailsIndex = _.findIndex(views, (v) => v.name === "labels_details");
if (detailsIndex !== -1) {
  views.splice(detailsIndex, 1, { ...views[detailsIndex], name: "labels_details", path: "/labels/:id/details" });
}

const returnsIndex = _.findIndex(views, (v) => v.name === "returns_details");
if (returnsIndex !== -1) {
  views.splice(returnsIndex, 1, { ...views[returnsIndex], name: "returns_details", path: "/returns/:id/details" });
}

const ordersIndex = _.findIndex(views, (v) => v.name === "wmsorders_details");
if (ordersIndex !== -1) {
  views.splice(ordersIndex, 1, { ...views[ordersIndex], name: "wmsorders_details", path: "/wmsorders/:id/details" });
}

const appsIndex = _.findIndex(views, (v) => v.name === "apps_details");
if (appsIndex !== -1) {
  views.splice(appsIndex, 1, { ...views[appsIndex], name: "apps_details", path: "/apps/:id/details" });
}
if (store && !store.account.enabled_services.transfer_enabled) {
  const accountSettingsIndex = _.findIndex(views, (v) => v.name === "account_settings");
  if (accountSettingsIndex !== -1) {
    const idx = views[accountSettingsIndex].children.findIndex((v) => v.name === "account_settings_pickup");

    views[accountSettingsIndex].children.splice(idx, 1);
  }
}

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  if (to.name && to.name !== "logout" && store.state.auth && !auth.loggedIn()) {
   /* if (store.state.auth.access_token) {
      store.dispatch("refresh").then(() => {
        next({ path: "/" });
        return;
      });
    } else {*/
      store.state.auth = null;
      store.dispatch("logout").then(() => {
        if (window.location.hostname.includes("coolrunner.dk")) {
          window.location.replace("https://coolrunner.dk/login");
          return;
        } else if (window.location.hostname.includes("homerunner.com")) {
          window.location.replace("https://homerunner.com/login");
          return;
        } else if (window.location.hostname === "localhost") {
          next({ name: "login" });
          return;
        }
      });
   /* }*/
    return;
  }

  if (!from.name && to.name !== "logout" && to.name !== "loginExternal") await store.dispatch("setAuthFromCookie");

  if (to.name === "logout") {
    store.dispatch("logout").then(() => {
     // next({ name: "login" });
    });
    return;
  }

  if (to.name === "login") {
    if (auth.loggedIn()) {
      next({ path: "/" });
      return;
    }

    if (window.location.hostname.includes("coolrunner.dk")) {
      window.location.replace("https://coolrunner.dk/login");
      return;
    } else if (window.location.hostname.includes("homerunner.com")) {
      window.location.replace("https://homerunner.com/login");
      return;
    }
    next();
    return;
  }

  const userEmail = store.state.account ? store.state.account.email : "";
  if (to.meta.disabled && !_.includes(allowOnDisabledPages, userEmail)) {
    next(false);
    return;
  }

  if (to.name === "home" && !store?.state?.account?.business) {
    next({ name: "labels" });
    return;
  }

  if (to.name === "account_settings_pickup" && !store?.state?.account?.enabled_services?.transfer_enabled) {
    next({ name: "home" });
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn()) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });

      return;
    }
  }
  next();
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/externalViews/Login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
  },
  {
    path: "/login/o",
    name: "loginExternal",
    component: () => import("@/externalViews/LoginExternal.vue"),
  },
  {
    path: "/subscribed",
    name: "subscribed",
    component: () => import("@/externalViews/Payment/Subscribed.vue"),
    meta: { loadInitial: false },
  },
  {
    path: "/order/success",
    name: "subscribed",
    component: () => import("@/externalViews/Payment/Order.vue"),
    meta: { loadInitial: false },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/components/core/View/Main"),
    meta: { requiresAuth: true },
    children: views,
  },
  {
    path: "/accountselect",
    name: "accountselect",
    component: () => import("@/externalViews/AccountSelect.vue"),
  },
  {
    path: "/onboarding",
    name: "onboarding",
    meta: { loadInitial: false },
    component: () => import("@/externalViews/Onboarding.vue"),
    children: [
      {
        path: ":flow",
        name: "onboarding_flow",
        component: () => import("@/externalViews/Onboarding.vue"),

        children: [
          {
            path: ":step",
            name: "onboarding_step",
            component: () => import("@/externalViews/Onboarding.vue"),
          },
        ],
      }
    ]
  }
];

const router = new VueRouter({
  stringifyQuery: (query) => {
    const result = Qs.stringify(query);
    return result ? `?${result}` : "";
  },
  parseQuery(query) {
    return Qs.parse(query);
  },
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(waitForStorageToBeReady);

export default router;

export { defaultMenu };
